export enum CompanyReportsChartDataTypesEnum {
  LrActivations = 'lr_activations',
  Sessions = 'sessions',
  LrScans = 'lr_scans',
  TrackScans = 'track_scans',
  BadgesPrinted = 'badges_printed',
  CompletedRegsitrations = 'completed_registrations',
  TransactionsRegTotal = 'transactions_reg_total',
  TransactionsLrTotal = 'transactions_lr_total',
  TransactionsTotal = 'transactions_total',
  ParticipantsInMultipleEvents = 'participants_in_multiple_events',
  ExhibitorsInMultipleEvents = 'exhibitors_in_multiple_events',
  ParticipantScannedInMultipleEvents = 'participant_scanned_in_multiple_events',
  ParticipantPrintedInMultipleEvents = 'participant_printed_in_multiple_events'
}
