import { Component, Vue, Watch } from 'vue-property-decorator'
import { Container } from 'typedi'
import { GChart } from 'vue-google-charts'
import { mapState } from 'vuex'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import moment from 'moment/moment'
import { Module } from '@/modules/common/enums/modules.enum'
import { CompanyReportsChartDataTypesEnum } from '@/modules/level-one/enums/company-reports-chart-data-types.enum'
@Component({
  name: 'GtrCompanyPerformance',
  computed: {
    ...mapState('company', ['company', 'companyLeaderboards', 'companyPerformanceStats', 'companyAttendeeSearch']),
    ...mapState('event', ['events'])
  }
})
export default class GtrCompanyPerformance extends GtrSuper {
  company!: Record<string, any>;
  events!: Record<string, any>;
  companyLeaderboards!: Record<string, any>;
  companyPerformanceStats!: Record<string, any>;
  companyAttendeeSearch!: Record<string, any>;

  Module = Module

  participantLookupTimeout: number | undefined

  data () {
    return {
      loading: false,
      dateRangeOptions: [
        {
          text: 'All Time',
          value: 10000
        },
        {
          text: 'Today',
          value: 0
        },
        {
          text: 'Yesterday',
          value: 1
        },
        {
          text: 'Last 7 Days',
          value: 7
        },
        {
          text: 'Last 14 Days',
          value: 14
        },
        {
          text: 'Last 30 Days',
          value: 30
        },
        {
          text: 'Last 3 Months',
          value: 90
        },
        {
          text: 'Last 6 Months',
          value: 180
        },
        {
          text: 'Last 12 Months',
          value: 365
        }
      ],
      daysBack: 10000,
      chartModuleOptions: [
        {
          text: 'All',
          value: CompanyReportsChartDataTypesEnum.TransactionsTotal
        }
      ],
      chartModule: CompanyReportsChartDataTypesEnum.TransactionsTotal,
      lineChartOptions: {
        chartArea: {
          width: '85%'
        },
        pointSize: 5,
        colors: ['#53A5E4'],
        hAxis: {
          viewWindowMode: 'pretty'
        }
      },
      lineChartData: [['Date', ''], ['', 0]],
      loadingChart: true,
      leaderboardResultQty: 5,
      leaderboardExportLoading: {},
      participantLookup: null as string | null,
      participantLookupMode: false,
      participantSearchResultsLoading: false,
      participantSearchResults: [],
      participantSearchResultHeaders: [
        {
          text: 'First name',
          align: 'start',
          sortable: true,
          value: 'participant_data.first_name'
        },
        {
          text: 'Last name',
          align: 'start',
          sortable: true,
          value: 'participant_data.last_name'
        },
        {
          text: 'Email',
          align: 'start',
          sortable: true,
          value: 'participant_data.email'
        },
        {
          text: 'Event',
          align: 'start',
          sortable: true,
          value: 'event_name'
        },
        {
          text: '',
          value: 'actions',
          searchable: false,
          sortable: false
        }
      ],
      participantSearchResultModalOpen: false
    }
  }

  async created () {
    try {
      if (!this.company) {
        await this.$store.dispatch('company/fetchCompany', this.$route.params.uuid)
      }
      if (this.events?.length === 0) {
        await this.$store.dispatch('event/loadEventsForCompany', { company_uuid: this.$route.params.uuid })
      }
      if (this.oneEventHasModuleActive[Module.REGISTRATION]) {
        this.$data.chartModuleOptions.push({
          text: 'Registration',
          value: CompanyReportsChartDataTypesEnum.TransactionsRegTotal
        })
      }
      if (this.oneEventHasModuleActive[Module.LEADS]) {
        this.$data.chartModuleOptions.push({
          text: 'Lead Retrieval',
          value: CompanyReportsChartDataTypesEnum.TransactionsLrTotal
        })
      }
      await this.fetchPerformanceStatistics()
      await this.$store.dispatch('company/fetchCompanyLeaderboards', this.$route.params.uuid)
      await this.loadChart()
    } catch (error) {
      Container.get(Notification).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  get oneEventHasModuleActive () {
    if (this.events?.[0]?.events?.length) {
      return Object.values(Module).reduce((accumulator, currentValue) => {
        accumulator[currentValue] = this.events[0].events.some(event => event.modules.some(mod => mod.module === currentValue))
        return accumulator
      }, {})
    }
    return {}
  }

  async fetchPerformanceStatistics () {
    await this.$store.dispatch('company/fetchCompanyPerformanceStats', {
      uuid: this.$route.params.uuid,
      daysBack: this.$data.daysBack
    })
  }

  async loadNewStatistics () {
    this.$data.loading = true
    try {
      await this.fetchPerformanceStatistics()
    } catch (error) {
      Container.get(Notification).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  async loadChart () {
    this.$data.loadingChart = true
    const daysBack = this.$data.daysBack
    const module = this.$data.chartModule
    const items: CompanyReportsChartDataTypesEnum[] = []
    if (module === CompanyReportsChartDataTypesEnum.TransactionsTotal) {
      this.$data.chartModuleOptions.forEach(item => {
        if (item.value !== CompanyReportsChartDataTypesEnum.TransactionsTotal) {
          items.push(item.value)
        }
      })
    } else {
      items.push(module)
    }
    const result = await this.$store.dispatch('company/getCompanyPerformanceChartData', { uuid: this.$route.params.uuid, daysBack, items })
    const countsByDate: any = {}
    Object.values(result).forEach((countsOfType: any) => {
      for (const date in countsOfType) {
        countsByDate[date] = (countsByDate[date] ?? 0) + countsOfType[date]
      }
    })
    if (!Object.keys(countsByDate).length) {
      this.$data.lineChartData = [['Date', ''], ['', 0]]
    } else {
      const lineChart = [['Date', '']]
      for (const date in countsByDate) {
        lineChart.push([
          moment(date).format('MM/DD/YYYY'),
          countsByDate[date]
        ])
      }
      this.$data.lineChartData = lineChart
    }
    this.$data.loadingChart = false
  }

  async handleDateRangeChange () {
    await this.loadNewStatistics()
    this.loadChart()
  }

  get moduleStatistics () {
    const statistics: any = {}
    for (const stat in this.companyPerformanceStats?.current_range_data) {
      statistics[stat] = this.getChangeForStatistic(stat)
      statistics[stat].title = this.companyPerformanceStats.current_range_data[stat].metadata.label
      statistics[stat].statistic = this.companyPerformanceStats.current_range_data[stat].total
    }
    return statistics
  }

  getChangeForStatistic (statistic: string) {
    let difference = 0
    let comparison = ''
    if (this.$data.daysBack < 10000) {
      const currentValue = this.companyPerformanceStats?.current_range_data[statistic].total
      const previousValue = this.companyPerformanceStats?.current_range_data[statistic].total
      difference = currentValue - previousValue

      if (difference !== 0) {
        const currentRange = this.$data.dateRangeOptions.find(item => item.value === this.$data.daysBack)
        let timePeriod = ''

        if (currentRange.text === 'Today' || currentRange.text === 'Yesterday') {
          timePeriod = 'day'
        } else {
          timePeriod = currentRange.text.replace('Last ', '').toLowerCase()
        }
        comparison = difference + (difference > 0 ? ' more' : ' less') + ' than previous ' + timePeriod
      }
    }
    return { difference: difference, comparison: comparison }
  }

  async exportLeaderboard (type: string) {
    try {
      Vue.set(this.$data.leaderboardExportLoading, type, true)
      const payload = {
        uuid: this.$route.params.uuid,
        type: type,
        limit: this.$data.leaderboardResultQty
      }
      await this.$store.dispatch('company/exportCompanyPerformanceLeaderboard', payload)
      Container.get(Notification).success('Your report has been downloaded.')
    } catch (e) {
      Container.get(Notification).error('There was a problem downloading your report. Please try again.')
    } finally {
      Vue.set(this.$data.leaderboardExportLoading, type, false)
    }
  }

  @Watch('companyAttendeeSearch')
  onCompanyAttendeeSearchChange () {
    this.$data.participantSearchResults = this.companyAttendeeSearch
    this.$data.participantSearchResultsLoading = false
    // this.$store.dispatch('common/hideLoader')
    // this.$data.participantSearchResultModalOpen = true
  }

  handleParticipantLookupChange () {
    clearTimeout(this.participantLookupTimeout)
    if (this.$data.participantLookup?.length > 2) {
      this.$data.participantLookupTimeout = window.setTimeout(this.participantSearch, 1000)
    }
  }

  async participantSearch () {
    this.$data.participantLookupMode = true
    try {
      // await this.$store.dispatch('common/showLoader', { value: true })
      this.$data.participantSearchResultsLoading = true
      const payload = {
        companyUuid: this.$route.params.uuid,
        criteria: { all: this.$data.participantLookup }
      }
      await this.$store.dispatch('company/saveCompanyAttendeeSearch', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  clearParticipantSearchResults () {
    this.$data.participantLookup = null
    this.$data.participantSearchResults = []
    this.$data.participantLookupMode = false
  }

  handleParticipantSearchResultModalClose () {
    this.$data.participantSearchResultModalOpen = false
  }
}
